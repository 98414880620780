import React, { useState, useEffect } from "react"
import HamburgerMenu from "./HamburgerMenu"

// Navbar when screen is small, or user has scrolled down
const SmallNavbar = ({ items }) => {
  // Is the small navbar collapsed or open (like a dropdown)
  const [isCollapsed, setIsCollapsed] = useState(true)

  const handleOpen = () => setIsCollapsed(false)
  const handleClose = () => setIsCollapsed(true)

  if (isCollapsed) {
    return (
      <div className="fixed top-0 right-0 w-full z-50">
        <div className="flex w-full justify-end pt-6 pr-6">
          <button
            type="button"
            aria-label="Open navigation menu"
            onClick={handleOpen}
            className="bg-sv-orange fill-white p-3 rounded-full w-12 h-12 flex justify-center items-center drop-shadow-lg hover:scale-105 hover:bg-sv-red transition-all ease-in-out duration-200"
          >
            <HamburgerMenu />
          </button>
        </div>
      </div>
    )
  }

  const listItems = items.map((item) => {
    return (
      <li className={`mb-2 last:mb-0`} key={`smallNav-${item.name}`}>
        <a href={item.link} className="group">
          {item.name}
          <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white"></span>
        </a>
      </li>
    )
  })

  return (
    <>
      <div className="fixed top-0 right-0 bg-sv-blue-dark text-white p-5 rounded-bl-3xl text-3xl font-bold drop-shadow-lg z-50">
        <button
          type="button"
          onClick={handleClose}
          className="text-right w-full mb-4 group"
          aria-label="Close navigation menu"
        >
          <span className="group-hover:bg-sv-blue-medium rounded-full px-2 transition-all ease-in-out duration-200">
            &times;
          </span>
        </button>
        <ul>{listItems}</ul>
      </div>
    </>
  )
}

// Navbar when screen is big and user is at top of page
const BigNavbar = ({ items, hidden }) => {
  const listItems = items.map((item) => {
    return (
      <li
        key={`bigNav-${item.name}`}
        className={`text-cyan-700 hover:text-cyan-500 group`}
        role="menuitem"
      >
        <a href={item.link}>{item.name}</a>
        {!item.active && (
          <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-1 bg-cyan-500"></span>
        )}
        {item.active && (
          <span className="block max-w-full h-1 bg-cyan-700 group-hover:bg-cyan-500"></span>
        )}
      </li>
    )
  })
  return (
    <>
      <nav
        className={`w-full ${hidden ? "opacity-0" : ""} z-50`}
        role="menubar"
      >
        <ul
          className="flex text-3xl 2xl:text-4xl 2xl:mb-10 font-bold justify-center items-center py-8 space-x-6"
          role="group"
        >
          {listItems}
        </ul>
      </nav>
    </>
  )
}

const Navbar = () => {
  // Is the navbar full width, or small dropdown menu
  const [isSmall, setIsSmall] = useState(false)

  // Is the window narrower than navbar breakpoint?
  const [isNarrow, setIsNarrow] = useState(false)

  // Is the window scrolled down? (not at the top)
  const [isScrolled, setIsScrolled] = useState(false)

  const navItems = [
    { name: "About", link: "#about", active: true },
    { name: "Tools", link: "#tools", active: false },
    { name: "Projects", link: "#projects", active: false },
    { name: "Contact", link: "#contact", active: false },
  ]

  // Set isNarrow on window resize
  useEffect(() => {
    // If window width is less than this breakpoint, use small navbar
    const breakPoint = 765

    const resizeHandler = () => {
      if (window.innerWidth <= breakPoint) {
        // console.log("Setting narrow")
        setIsNarrow(true)
      } else {
        // console.log("setting not narrow")
        setIsNarrow(false)
      }
    }

    resizeHandler() // call so that it runs on page load

    window.addEventListener("resize", resizeHandler)

    return () => {
      window.removeEventListener("resize", resizeHandler)
    }
  }, [])

  // Set isScrolled on scroll event
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY

      if (position > 96) {
        setIsScrolled(true)
      }

      if (position < 96) {
        setIsScrolled(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  // set isSmall based on isNarrow and isScrolled
  useEffect(() => {
    // console.log(`isSmall? ${isNarrow || isScrolled}`)
    setIsSmall(isNarrow || isScrolled)
  }, [isNarrow, isScrolled])

  return (
    <div className="w-full">
      {isSmall && <SmallNavbar items={navItems} />}
      <BigNavbar items={navItems} hidden={isSmall} />
    </div>
  )
}

export default Navbar
