import React from "react"

export default function RailsSvg({ color, ...props }) {
  color = color || "#C00"
  return (
    <svg
      viewBox="0 0 128 128"
      {...props}
      role="img"
      aria-label="Ruby on Rails icon"
    >
      <path
        fill={color}
        d="M122 72.3h-5.3v-2.7h10.5v-5.9h-10c-2.6 0-6.1 2.1-6.1 6.1v2c0 4 3.4 6 6.1 6h5v2.8l-10.8.1v5.9H122c2.2 0 5.9-1.6 6-6v-2.3c0-3.8-3.1-6-6-6zM60.4 71.4c0-7.3-6.9-8-6.9-8H41.2v23.1h6.2V81l5.4 5.6H62l-7.3-7.4s5.7-.5 5.7-7.8zm-8.1 3.1h-5v-5.3h5s1.4.5 1.4 2.6c0 2.2-1.4 2.7-1.4 2.7zM75.7 63.6h-6.3c-4.5 0-6 4.1-6 6v16.9h6.3v-4h5.9v4h6.1V69.6c0-4.9-4.4-6-6-6zm-.1 12.3h-6v-5.6s0-1.3 2-1.3h2.2c1.7 0 1.8 1.3 1.8 1.3v5.6zM84.6 63.6h6.6v22.9h-6.6zM100.4 63.6h-6.5v22.9h15.3v-5.9h-8.8z"
      ></path>
      <path
        fill={color}
        d="M72.2 60.7c.6-.5 1.1-.9 1.1-.9S56.5 43 37.7 44.9c-9.4.8-21 9.4-27.8 20.7-6.8 11.3-7.7 20.9-7.7 20.9h25.6s-4.9-22.3 11.3-31.3c3.5-1.7 14.8-8.1 33.1 5.5zM55.5 44.5c-.3-.2-1.1-.6-3.1-1.1l-.1 2.1c1.1.4 2.1.8 3.1 1.2l.1-2.2z"
      ></path>
      <path
        fill={color}
        d="M55.5 55.9l.1-2c-1.1-.2-2.2-.3-3.2-.4l-.1 2c1.1 0 2.1.2 3.2.4zM40.4 43.5h.3l-.6-2c-1 0-2 .1-3.1.2l.6 1.9c.9-.1 1.8-.1 2.8-.1zM42.6 57.6c.9-.5 1.9-.8 2.8-1.1l-.7-2.1c-1 .2-2 .6-2.8 1l.7 2.2zM27.2 46.9l-1.5-2.2c-.7.3-1.6.8-2.4 1.3l1.5 2.3c.8-.5 1.6-1 2.4-1.4zM33.9 61.4l1.6 2.3c.6-.8 1.2-1.6 1.9-2.3l-1.5-2.2c-.8.7-1.4 1.5-2 2.2zM30.2 68.3c-.5 1.2-.8 2.4-1 3.5l2.6 2.1c.1-1.3.4-2.5.7-3.8l-2.3-1.8zM15 56.5l-2.3-2c-.8.8-1.7 1.6-2.4 2.4l2.5 2.1c.7-.9 1.4-1.7 2.2-2.5zM5.2 70.9l-3.7-1.4c-.6 1.4-1.3 3-1.6 3.9l3.7 1.4c.4-1.1 1.1-2.7 1.6-3.9zM28.7 80c.1 1.7.2 3.1.4 4.1l3.9 1.4c-.3-1.3-.6-2.7-.8-4.2L28.7 80z"
      ></path>
    </svg>
  )
}
