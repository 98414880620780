import React from "react"
import { Tooltip } from "flowbite-react"
import Section, { SectionHeading } from "../Section"
import {
  CssSvg,
  EslintSvg,
  ExpressSvg,
  GithubSvg,
  GitSvg,
  HtmlSvg,
  JsSvg,
  MongoSvg,
  NodeSvg,
  PostgresqlSvg,
  PySvg,
  RailsSvg,
  ReactSvg,
  RubySvg,
  SassSvg,
  SequelizeSvg,
  TailwindSvg,
  TsSvg,
  StorybookSvg,
  JestSvg,
} from "../SVG"
import MasonryGrid from "../ui/MasonryGrid"
import { StaticImage } from "gatsby-plugin-image"

function Tools() {
  const fillColor = "#bb3e04"
  const bgColor = "#e9d8a6"
  const svgClasses =
    "w-full hover:translate-y-2 transition-transform duration-200 ease-in-out max-h-20 md:max-h-36"
  return (
    <Section
      className="bg-sv-beige-mid flex flex-col justify-between"
      id="tools"
      nextBg="bg-radial-at-r to-sv-blue-dark from-sv-teal-light"
    >
      <div className="w-full">
        <SectionHeading className="text-sv-red mx-auto max-w-[2000px]">
          My Toolbox
        </SectionHeading>
      </div>
      <div className="max-w-4xl xl:max-w-7xl mx-auto">
        <MasonryGrid>
          <Tooltip content="JavaScript" trigger="hover">
            <JsSvg
              color={fillColor}
              bgColor={bgColor}
              className={`${svgClasses}`}
            />
          </Tooltip>
          <Tooltip content="Ruby" trigger="hover">
            <RubySvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
          <Tooltip content="Python" trigger="hover">
            <PySvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
          <Tooltip content="HTML5" trigger="hover">
            <HtmlSvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
          <Tooltip content="TypeScript" trigger="hover">
            <TsSvg
              color={fillColor}
              bgColor={bgColor}
              className={`${svgClasses}`}
            />
          </Tooltip>
          <Tooltip content="CSS3" trigger="hover">
            <CssSvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
          <Tooltip content="React" trigger="hover">
            <ReactSvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
          <Tooltip content="ExpressJS" trigger="hover">
            <ExpressSvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
          <Tooltip content="NodeJS" trigger="hover">
            <NodeSvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
          <Tooltip content="Ruby on Rails" trigger="hover">
            <RailsSvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
          <Tooltip content="MongoDB" trigger="hover">
            <MongoSvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
          <Tooltip content="PostgreSQL" trigger="hover">
            <PostgresqlSvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
          <Tooltip content="Git" trigger="hover">
            <GitSvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
          <Tooltip content="Sequelize" trigger="hover">
            <SequelizeSvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
          <Tooltip content="Sass (SCSS)" trigger="hover">
            <SassSvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
          <Tooltip content="TailwindCSS" trigger="hover">
            <TailwindSvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
          <Tooltip content="ESLint" trigger="hover">
            <EslintSvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
          <Tooltip content="Github" trigger="hover">
            <GithubSvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
          <Tooltip content="Storybook" trigger="hover">
            <StorybookSvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
          <Tooltip content="Jest" trigger="hover">
            <JestSvg color={fillColor} className={`${svgClasses}`} />
          </Tooltip>
        </MasonryGrid>
      </div>
      <div className="self-start w-full flex justify-between xl:max-w-[2000px] mx-auto">
        <StaticImage
          src="../../images/toolbox@4x.png"
          alt="Toolbox with a hammer and wrench"
          width={2000}
          height={1200}
          className="max-w-lg self-end"
        />
        <StaticImage
          src="../../images/snake-plant@2x.png"
          alt="Decorative snake plant in a pot"
          width={203}
          height={442}
          className="hidden lg:block"
        />
      </div>
    </Section>
  )
}

export default Tools
