import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Project from "../Project"

import Section, { SectionHeading } from "../Section"

const projectData = [
  {
    title: "dettiPOS",
    description: "Bespoke Point of Sale system",
    image: (
      <StaticImage
        src={`../../images/screenshots/detti.png`}
        alt="Detti POS Order Screen"
        className="rounded-lg"
      />
    ),
    tools: ["node", "react", "mongo"],
    link: "/projects/detti-pos",
  },
  {
    title: "MarketPlace",
    description: "e-commerce website with payment processing",
    image: (
      <StaticImage
        src={`../../images/screenshots/marketplace.png`}
        alt="MarketPlace Cart Screen"
        className="rounded-lg"
      />
    ),
    tools: ["typescript", "mongo", "stripe"],
    link: "/projects/marketplace",
  },
  {
    title: "Intrvl.",
    description: "Custom interval timers",
    image: (
      <StaticImage
        src={`../../images/screenshots/intrvl.png`}
        alt="Intrvl. Timer Screen"
        className="rounded-lg"
      />
    ),
    tools: ["node", "postgresql", "tailwindcss"],
    link: "/projects/intrvl",
  },
  {
    title: "Bodhi's Dreamworld",
    description: "2D platform game",
    image: (
      <StaticImage
        src={`../../images/screenshots/bodhi.png`}
        alt="Intrvl. Timer Screen"
        className="rounded-lg"
      />
    ),
    tools: ["javascript", "html", "css"],
    link: "/projects/bodhi-dreamworld",
  },
]

function Projects() {
  const projects = projectData.map((project) => (
    <Project key={project.title} {...project} />
  ))
  return (
    <Section
      id="projects"
      className="bg-radial-at-r to-sv-blue-dark from-sv-teal-light flex flex-col"
      nextBg="bg-sv-beige-mid"
    >
      <div className="max-w-[2000px] mx-auto w-full flex flex-col grow">
        <div className="absolute max-w-[10%] xl:max-w-[200px] top-0 right-5 2xl:right-24 z-20 group hidden lg:block">
          <StaticImage
            src="../../images/hanging-plant@2x.png"
            alt="Toolbox with a hammer and wrench"
            width={364}
            height={884}
            className="group-hover:-rotate-12 origin-top transition-transform duration-700 ease-[cubic-bezier(.6,-0.62,.29,1.69)]"
          />
        </div>
        <SectionHeading className="text-white">Projects</SectionHeading>
        <div className="flex flex-wrap justify-center items-center gap-6 mb-10 lg:mr-36 grow">
          {projects}
        </div>
      </div>
    </Section>
  )
}

export default Projects
