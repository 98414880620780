import React from "react"

export default function MasonryGrid({ children }) {
  const bricks = children.map((child, index) => {
    const shouldBeOffset = (index - 8) % 5 === 0 || index === 3

    return (
      <div
        key={`brick-${index}`}
        className={`col-span-4 md:col-span-1 ${
          shouldBeOffset ? "col-start-3" : ""
        }`}
      >
        {child}
      </div>
    )
  })
  return (
    <div className="grid grid-cols-12 lg:grid-cols-6 md:grid-cols-5 gap-3 lg:gap-10 mb-8">
      {bricks}
    </div>
  )
}
