import React from "react"

export default function SequelizeSvg({ color, ...props }) {
  color = color || "#2f406a"
  return (
    <svg
      viewBox="0 0 128 128"
      {...props}
      role="img"
      aria-label="Sequelize icon"
    >
      <g fill={color}>
        <path d="M101.84 41.72V86l-37.66 22.32-.34.31v16.57l.34.32 53-30.64V33.12l-.5-.12-15 8.36.08.36"></path>
        <path d="M26.4 86.4l37.78 21.92v17.2l-53.4-30.76V33.24l.55-.08 14.91 8.67.16.5V86.4"></path>
        <path d="M26.4 42.32l-15.62-9.08L64.06 2.48l53.16 30.64-15.38 8.6-37.78-21.56L26.4 42.32M30.06 84V65.9l.41.24L45 74.59l.12.07v17.53L30.06 84M83.27 92.6V75l.1-.05 14.27-8.35.44-.26v17.5L83.27 92.6M64.85 103.46l-.41-.42V86l.51-.18 14.27-8.32.44-.25V94.7l-14.8 8.76"></path>
        <path d="M49.8 95.23V77.17l.41.23 14.51 8.45.12 17.61-15.04-8.23M63.29 22.94L48.48 31.7l15.05 8.76 14.8-8.64-15.04-8.88M30.06 60.36V42.55h.41L45 51l.12.33v17.26l-15.06-8.23"></path>
        <path d="M44.86 33.8l-14.8 8.76 15.05 8.76 14.8-8.64-15.05-8.88M83.27 69V51.7l.1-.41L97.64 43l.44.1v17.11L83.27 69"></path>
        <path d="M83 34.21L68.22 43l15.05 8.76 14.8-8.64L83 34.21M64.85 79.85l-.41-.42V62.79l.41-.21.1-.41 14.27-8.32.44.1v17.14l-14.8 8.76"></path>
        <path d="M49.8 71.63V53.82h.41l14.51 8.45.12.33v17.25L49.8 71.63"></path>
        <path d="M64.6 45.06l-14.8 8.76 15.05 8.76 14.8-8.64-15.05-8.88"></path>
      </g>
    </svg>
  )
}
