import Section from "../Section"
import Navbar from "../Navbar"
import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

function About() {
  const [expanded, setExpanded] = useState(false)

  return (
    <Section
      className="bg-radial-at-tl from-sv-beige-mid  to-sv-teal-light pb-0 flex flex-col justify-between"
      nextBg="bg-sv-beige-mid"
      id="about"
    >
      <Navbar />
      <div className="grid grid-cols-5 xl:grid-cols-2 grow items-end max-w-screen-2xl mx-auto">
        <h1 className="sr-only">About Me</h1>
        <div className="col-span-5 row-start-2 lg:row-start-1 lg:col-span-2 xl:col-span-1 sm:max-w-md sm:mx-auto lg:max-w-none">
          <StaticImage
            src="../../images/stefan-wave-hi@2x.png"
            alt="Cartoon of Stefan Vosloo waving with a speech bubble saying 'Hi!'"
            width={1443}
            height={1156}
          />
        </div>
        <div className="p-8 pb-0 flex flex-col col-span-5 xl:col-span-1 row-start-1 lg:col-span-3 h-full">
          <div className="font-typewriter text-sv-red">
            <p className="text-7xl lg:text-8xl mb-2">I'm Stefan,</p>
            <p className="text-5xl lg:text-6xl xl:text-7xl mb-5">
              a full-stack web developer.
            </p>
          </div>
          <div className="text-slate-800 mb-8">
            <p className="text-3xl md:text-4xl font-semibold mb-2">
              I love working with others to create exceptional applications.
            </p>
            <p className="text-2xl md:text-3xl">
              I am based in Seattle, WA and available for remote work, too.
            </p>
            <button
              type="button"
              onClick={() => setExpanded((current) => !current)}
              className="font-bold text-sv-blue-dark text-xl lg:text-2xl mt-8"
            >
              {expanded ? "Collapse ⬆" : "Learn More ⬇"}
            </button>
            <div
              className={`space-y-2 text-2xl mt-5 ${expanded ? "" : "hidden"}`}
            >
              <p>
                I’m a Software Engineer at{" "}
                <span className="font-bold">Amazon</span> working on{" "}
                <span className="font-bold">Prime Video</span> and I have a
                background in <span className="font-bold">teaching</span> and{" "}
                <span className="font-bold">Information Science</span>.
              </p>
              <p>
                I bring a combination of <span className="font-bold">art</span>{" "}
                and <span className="font-bold">engineering</span> to
                collaborative teams to build{" "}
                <span className="font-bold">beautiful</span> and{" "}
                <span className="font-bold">maintainable</span> applications.
              </p>
              <p>
                Having lived in various countries, I have gained a{" "}
                <span className="font-bold">multicultural perspective</span>{" "}
                that helps me lead with{" "}
                <span className="font-bold">empathy</span> and{" "}
                <span className="font-bold">humility</span>. I am{" "}
                <span className="font-bold">self-motivated</span> and
                continuously seek opportunities to grow and learn on developer
                teams.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default About
