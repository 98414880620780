import React from "react"

export function SectionHeading({ className, children }) {
  return (
    <h2 className={`text-5xl xl:text-7xl font-typewriter p-8 ${className}`}>
      {children}
    </h2>
  )
}

export default function Section({ children, className, nextBg, id }) {
  return (
    <>
      <div className="relative" id={id}>
        <div className={`min-h-screen p-5 lg:rounded-b-[75px] ${className}`}>
          {children}
        </div>

        {nextBg && (
          <div
            className={`h-[75px] ${nextBg} absolute bottom-0 w-full -z-20`}
          ></div>
        )}
      </div>
    </>
  )
}
