import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Section from "../Section"
import resume from "../../Stefan Vosloo Resume.pdf"

function Contact() {
  return (
    <Section
      className="bg-sv-beige-mid flex flex-col"
      id="contact"
      nextBg="bg-sv-beige-mid"
    >
      <div className="p-8 w-full max-w-[2000px] mx-auto">
        <h2 className="text-5xl lg:text-6xl xl:text-7xl font-typewriter text-sv-orange">
          Get in touch.
        </h2>
        <p className="text-3xl lg:text-4xl xl:text-5xl text-sv-blue-dark">
          I'd love to hear from you.
        </p>
      </div>

      <form
        action="https://formspree.io/f/moqrddyl"
        method="post"
        className="grid gap-y-5 gap-x-10 grid-cols-5 auto-rows-min md:p-16 my-auto w-full max-w-7xl mx-auto"
      >
        <div className="col-start-1 col-span-6 md:col-start-3 md:col-span-3 flex justify-around">
          <a
            href="mailto:stefanvosloo1@gmail.com"
            className="hover:scale-105 transition-all ease-in-out duration-200 h-[50px] w-[50px]"
          >
            <StaticImage
              src="../../images/mail-icon.png"
              alt="Email"
              width={100}
              height={100}
            />
          </a>

          <a
            href="https://www.linkedin.com/in/stefan-vosloo/"
            className="hover:scale-105 transition-all ease-in-out duration-200 h-[50px] w-[50px]"
          >
            <StaticImage
              className=""
              src="../../images/linkedin-icon.png"
              alt="LinkedIn"
              width={100}
              height={100}
            />
            {/* <img
              src="images/svg/link-icon-linkedin.svg"
              alt="Linkedin icon"
              className="h-full min-w-[50px]"
            /> */}
          </a>

          <a
            href="https://github.com/saulthebear"
            className="hover:scale-105 transition-all ease-in-out duration-200 h-[50px] w-[50px]"
          >
            <StaticImage
              src="../../images/github-icon.png"
              alt="Github"
              width={100}
              height={100}
            />
            {/* <img
              src="images/svg/link-icon-github.svg"
              alt="Github icon"
              className="h-full min-w-[50px]"
            /> */}
          </a>
        </div>

        {/* <div className="col-start-1 col-span-6 md:col-start-3 md:col-span-3">
          <a
            href={resume}
            className=" bg-sv-orange py-3 px-10 lg:px-20 rounded-full flex justify-center items-center text-white text-2xl font-semibold  hover:bg-sv-red hover:scale-105 transition-all ease-in-out duration-200"
          >
            <span className="material-symbols-rounded text-3xl mx-3 font-semibold">
              download
            </span>
            Resume
          </a>
        </div> */}

        <label
          htmlFor="contact-name"
          className="xl:text-5xl col-start-1 col-span-6 md:col-start-1 md:col-span-2 text-center md:text-right text-sv-orange text-4xl font-bold mr-5"
        >
          Name:
        </label>
        <input
          type="text"
          name="contact-name"
          id="contact-name"
          className="col-start-1 col-span-6 md:col-start-3 md:col-span-3 py-2 px-5 xl:py-3 xl:px-8 rounded-3xl border-4 border-[#bea966] text-2xl"
        />

        <label
          htmlFor="contact-email"
          className="xl:text-5xl col-start-1 col-span-6 md:col-start-1 md:col-span-2 text-center md:text-right text-sv-orange text-4xl font-bold mr-5"
        >
          Email:
        </label>
        <input
          type="email"
          name="contact-email"
          id="contact-email"
          className="col-start-1 col-span-6 md:col-start-3 md:col-span-3 py-2 px-5 xl:py-3 xl:px-8 rounded-3xl border-4 border-[#bea966] text-2xl"
        />

        <label
          htmlFor="contact-message"
          className="xl:text-5xl col-start-1 col-span-6 md:col-start-1 md:col-span-2 text-center md:text-right text-sv-orange text-4xl font-bold mr-5"
        >
          Message:
        </label>
        <textarea
          cols="30"
          rows="10"
          type="text"
          name="contact-message"
          id="contact-message"
          className="col-start-1 col-span-6 md:col-start-3 md:col-span-3 py-2 px-5 xl:py-3 xl:px-8 rounded-3xl border-4 border-[#bea966] text-2xl"
        ></textarea>

        <button
          type="submit"
          aria-label="Send message"
          className="col-start-1 col-span-6 md:col-start-3 md:col-span-3 bg-sv-orange py-3 px-10 lg:px-20 rounded-full flex justify-center items-center hover:bg-sv-red hover:scale-105 transition-all ease-in-out duration-200"
        >
          <span className="text-white text-2xl font-semibold mr-5">Send</span>
          <StaticImage
            src="../../images/svg/send-mail.svg"
            alt="Send mail icon"
            className="h-10 translate-x-[15%]"
            width={50}
            height={50}
          />
        </button>
      </form>
      <div className="w-full max-w-[2000px] mx-auto">
        <div className="hidden md:block max-w-md -mt-48 -mb-5 xl:max-w-2xl xl:-mt-64">
          <StaticImage
            src="../../images/stefan-desk@2x.png"
            alt="Stefan sitting at a desk working on a laptop"
            width={1285}
            height={1415}
          />
        </div>
      </div>
    </Section>
  )
}

export default Contact
