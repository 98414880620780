import * as React from "react"
import About from "../components/sections/About"
import Tools from "../components/sections/Tools"
import Projects from "../components/sections/Projects"
import Contact from "../components/sections/Contact"
import { SEO } from "../components/seo"

const IndexPage = () => {
  return (
    <>
      <main className="font-montserrat overflow-x-hidden">
        <title>Stefan Vosloo | Software Engineer</title>
        <About />
        <Tools />
        <Projects />
        <Contact />
      </main>
    </>
  )
}

export default IndexPage

export const Head = () => <SEO />
